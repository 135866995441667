var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { EditorView, keymap } from "@codemirror/view";
import { EditorState } from "@codemirror/state";
import { basicSetup } from "codemirror";
import { defaultKeymap, indentLess, indentMore } from "@codemirror/commands";
import { json } from "@codemirror/lang-json";
import { oneDark } from "@codemirror/theme-one-dark";
import * as pako from "pako";
import { Base64 } from "js-base64";
import { BehaviorSubject, fromEvent, merge } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import Mustache from "mustache";
import JSON5 from 'json5';
export var insertTab = function (_a) {
    var state = _a.state, dispatch = _a.dispatch;
    var line = state.doc.lineAt(state.selection.main.from);
    var col = state.selection.main.from - line.from;
    var spaces_to_insert = " ".repeat(state.tabSize - (col % state.tabSize));
    dispatch(state.update(state.replaceSelection(spaces_to_insert)));
    return true;
};
var starting_json_content = "{\n    // Enter any JSON (with optional\n    // comments, like this one), in\n    // this text editor window.\n\n    \"hello\":\"world\",\n    \"key\":\"value\"\n\n    /*\n        The keys (\"hello\") and values\n        (\"world\") can be used in the\n        next editor window using\n        Mustache templates.\n    */\n}\n";
var starting_mustache_content = "\nUse any valid Mustache template\nsyntax in this editor.\n\n{{hello}}\n\nSee:\n<a href=\"https://mustache.github.io/mustache.5.html\">https://mustache.github.io/mustache.5.html</a>\nfor more documentation on Mustache\ntemplates.\n\nAny reference to keys from the JSON\nobject editor window will output the\nvalues using this template.\n\nOptionally, you can leave this window\nempty to simply output the formatted\nJSON object.\n\nThere are also UTC date values available:\n\n{{ utcnow.iso }}\n{{ utcnow.year }}-{{ utcnow.month }}-{{ utcnow.day }}T{{ utcnow.hour }}:{{ utcnow.minute }}:{{ utcnow.second }}\n";
// Initial load
if (window.location.hash != null && window.location.hash) {
    var hash = window.location.hash;
    try {
        var u8 = Base64.toUint8Array(hash);
        var inflated = pako.inflate(u8);
        if (inflated != null && inflated) {
            var utf8decoder = new TextDecoder();
            var json_str = utf8decoder.decode(inflated);
            var obj = JSON5.parse(json_str);
            console.log(obj);
            if (obj != null && obj.j !== 'undefined' && obj.j != null) {
                starting_json_content = obj.j;
            }
            if (obj != null && obj.m !== 'undefined' && obj.m != null) {
                starting_mustache_content = obj.m;
            }
        }
    }
    catch (err) {
        console.log(err);
    }
}
var common_setup_array = [
    basicSetup,
    oneDark,
    EditorState.tabSize.of(4),
    keymap.of(__spreadArray(__spreadArray([], defaultKeymap, true), [
        {
            key: "Ctrl-]",
            preventDefault: true,
            run: indentMore,
        },
        {
            key: "Ctrl-[",
            preventDefault: true,
            run: indentLess,
        },
        {
            key: "Tab",
            preventDefault: true,
            run: insertTab,
        }
    ], false))
];
var json_bs = new BehaviorSubject(starting_json_content);
var mustache_bs = new BehaviorSubject(starting_mustache_content);
var json_view = new EditorView({
    state: EditorState.create({
        doc: starting_json_content,
        extensions: __spreadArray(__spreadArray([], common_setup_array, true), [
            json(),
            EditorState.changeFilter.of(function (tr) {
                if (tr.docChanged) {
                    json_bs.next(tr.state.doc.sliceString(0));
                }
                return true;
            })
        ], false)
    })
});
var mustache_view = new EditorView({
    state: EditorState.create({
        doc: starting_mustache_content,
        extensions: __spreadArray(__spreadArray([], common_setup_array, true), [
            EditorState.changeFilter.of(function (tr) {
                if (tr.docChanged) {
                    mustache_bs.next(tr.state.doc.sliceString(0));
                }
                return true;
            })
        ], false),
    })
});
function updateContentFromHashIfNecessary() {
    if (window.location.hash != null && window.location.hash) {
        var hash = window.location.hash;
        try {
            var u8 = Base64.toUint8Array(hash);
            var inflated = pako.inflate(u8);
            if (inflated != null && inflated) {
                var utf8decoder = new TextDecoder();
                var json_str = utf8decoder.decode(inflated);
                var obj = JSON5.parse(json_str);
                if (obj != null && obj.j !== 'undefined' && obj.j != null) {
                    var json_content = obj.j;
                    if (json_content != json_view.state.doc.sliceString(0)) {
                        // Hash content differs, update the view
                        // console.log( 'updating json view' );
                        json_view.dispatch(json_view.state.update({
                            changes: {
                                from: 0,
                                to: json_view.state.doc.length,
                                insert: json_content
                            }
                        }));
                    }
                }
                if (obj != null && obj.m !== 'undefined' && obj.m != null) {
                    var mustache_content = obj.m;
                    if (mustache_content != mustache_view.state.doc.sliceString(0)) {
                        // Hash content differs, update the view
                        // console.log( 'updating mustache view' );
                        mustache_view.dispatch(mustache_view.state.update({
                            changes: {
                                from: 0,
                                to: mustache_view.state.doc.length,
                                insert: mustache_content
                            }
                        }));
                    }
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }
}
var is_typing = false;
// Consider ourselves 'typing' if we've seen
// a document change / transaction in the last
// 1000ms
merge(json_bs, mustache_bs)
    .subscribe(function () { is_typing = true; });
merge(json_bs, mustache_bs)
    .pipe(debounceTime(1000))
    .subscribe(function () { is_typing = false; });
// Render any mustache changes after 250ms
// of no activity.
merge(json_bs, mustache_bs).pipe(debounceTime(250)).subscribe(function (_) {
    renderMustache();
});
// Update the hash after 250ms of inactivity
merge(json_bs, mustache_bs).pipe(debounceTime(250))
    .subscribe(function (_) {
    updateHash();
});
var popstate_event = fromEvent(window, 'popstate');
// Detect pop state, but filter if we see that we've been
// typing in the last 1000ms (is_typing).
popstate_event.pipe(filter(function () { return !is_typing; }), debounceTime(250)).subscribe(function (_) {
    // console.log( 'popped state!');
    updateContentFromHashIfNecessary();
});
function updateHash() {
    var err_array = [];
    if (json_view && mustache_view) {
        var payload = {
            j: json_view.state.doc.sliceString(0),
            m: mustache_view.state.doc.sliceString(0)
        };
        var stringified = JSON.stringify(payload);
        var deflated = pako.deflate(stringified);
        var encoded = Base64.fromUint8Array(deflated);
        window.location.hash = encoded;
    }
}
function renderMustache() {
    var err_array = [];
    if (json_view && mustache_view) {
        var obj = null;
        try {
            obj = JSON5.parse(json_view.state.doc.sliceString(0));
        }
        catch (err) {
            err_array.push(err);
        }
        if (obj !== null && typeof (obj) === 'object') {
            var now = new Date(Date.now());
            obj['utcnow'] = {
                "iso": now.toISOString(),
                "locale": now.toLocaleString(),
                "year": String(now.getUTCFullYear()).padStart(4, '0'),
                "month": String(now.getUTCMonth() + 1).padStart(2, '0'),
                "day": String(now.getUTCDate()).padStart(2, '0'),
                "hour": String(now.getUTCHours()).padStart(2, '0'),
                "minute": String(now.getUTCMinutes()).padStart(2, '0'),
                "second": String(now.getUTCSeconds()).padStart(2, '0')
            };
            obj['now'] = {
                "iso": now.toISOString(),
                "locale": now.toLocaleString(),
                "year": String(now.getFullYear()).padStart(4, '0'),
                "month": String(now.getMonth() + 1).padStart(2, '0'),
                "day": String(now.getDate()).padStart(2, '0'),
                "hour": String(now.getHours()).padStart(2, '0'),
                "minute": String(now.getMinutes()).padStart(2, '0'),
                "second": String(now.getSeconds()).padStart(2, '0')
            };
        }
        var mustache_template = null;
        try {
            mustache_template = mustache_view.state.doc.sliceString(0);
        }
        catch (err) {
            err_array.push(err);
        }
        var output = null;
        if (err_array.length <= 0 && obj != null && mustache_template != null) {
            try {
                if (mustache_template.trim() == "") {
                    output = JSON5.stringify(obj, null, 2);
                }
                else {
                    output = Mustache.render(mustache_template, obj);
                }
            }
            catch (err) {
                err_array.push(err);
            }
        }
        if (err_array.length <= 0 && output) {
            mustache_output_el.innerHTML = '';
            mustache_output_el.innerHTML = output;
        }
        else {
            mustache_output_el.innerHTML = '';
            mustache_output_el.innerHTML = err_array.join('\n');
        }
    }
    title_el.innerHTML = '';
    title_el.innerText = "".concat(window.location.toString().length, " out of 2000");
}
;
var json_view_el = document.body.querySelector('#json-view');
var mustache_view_el = document.body.querySelector('#mustache-view');
var mustache_output_el = document.body.querySelector('#mustache-output');
var title_el = document.head.querySelector('title');
if (json_view_el) {
    json_view_el.appendChild(json_view.dom);
}
if (mustache_view_el) {
    mustache_view_el.appendChild(mustache_view.dom);
}
setTimeout(function () {
    renderMustache();
}, 10);
